import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gis from "/src/components/img/gis"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Název mapy" />
    <h1>Název mapy</h1>

    <p><strong>N&aacute;zev mapy</strong>&nbsp;je nejdůležitěj&scaron;&iacute; p&iacute;smenn&yacute; prvek na mapě, kter&yacute; obsahuje věcn&eacute;, prostorov&eacute; a časov&eacute; určen&iacute; mapovan&eacute;ho jevu. Zpravidla se umisťuje k horn&iacute;mu okraji mapy a p&iacute;&scaron;e se dostatečně velk&yacute;m a čiteln&yacute;m p&iacute;smem. Jde o&nbsp;<Link to="/gis/mapova-kompozice/">z&aacute;kladn&iacute; kompozičn&iacute; prvek</Link>&nbsp;a neměl by tak na vět&scaron;ině map chybět. Z popsan&yacute;ch pravidel se mohou v&iacute;ce či m&eacute;ně vymykat mapov&eacute; port&aacute;ly, ale tak&eacute; atlasy.</p>
    <hr /><h2>Jak spr&aacute;vně vytvořit n&aacute;zev mapy?</h2>
    <p>T&eacute;ma mapy, kter&eacute; kartograf zpracov&aacute;v&aacute;, mus&iacute; b&yacute;t jasně, stručně a v&yacute;stižně obsaženo v n&aacute;zvu. Proto mus&iacute; obs&aacute;hnout vymezen&iacute; věcn&eacute; (m&iacute;ra nezaměstnanosti), prostorov&eacute; (v Libereck&eacute;m kraji) a časov&eacute; (v roce 2018). U jevů, kter&eacute; nejsou v čase v&yacute;znamně proměnliv&eacute;, nen&iacute; třeba uv&aacute;dět časov&eacute; určen&iacute; &ndash; typicky jde o fyzickogeografick&aacute; t&eacute;mata, jako např&iacute;klad nadmořsk&aacute; v&yacute;&scaron;ka, půdn&iacute; typy apod.</p>
    <p>V n&aacute;zvu se nepouž&iacute;v&aacute; slovo &bdquo;mapa&ldquo;, jelikož je od pohledu zřejm&eacute;, že se o mapu jedn&aacute;. &Scaron;lo by tak o zbytečnou, redundantn&iacute; informaci, kter&yacute;ch se v n&aacute;zvu snaž&iacute;me kvůli zachov&aacute;n&iacute; stručnosti vyvarovat.</p>
    <p>N&aacute;zev by měl b&yacute;t čiteln&yacute; jako jedin&yacute; textov&yacute; prvek z vět&scaron;&iacute; vzd&aacute;lenosti. Proto bychom měli volit jednoduch&yacute; bezpatkov&yacute; font, např&iacute;klad Arial. Serifov&yacute; (patkov&yacute;) typ p&iacute;sma může pr&aacute;vě při četbě z d&aacute;lky činit probl&eacute;my. D&aacute;le by měl b&yacute;t ide&aacute;lně naps&aacute;n kapit&aacute;lkami, v angličtině označovan&eacute; jako smallcaps (př&iacute;klad:&nbsp;Aᴀ Bʙ Cᴄ Mɪᴄʜᴀᴇʟᴀ), popř&iacute;padě verz&aacute;lkami (př&iacute;klad: AA BB CC MICHAELA).</p>
    <p>Pokud by byl n&aacute;zev př&iacute;li&scaron; dlouh&yacute;, je vhodn&eacute; ho rozdělit na titul a podtitul. Titul obsahuje věcn&eacute; vymezen&iacute;, někdy i vymezen&iacute; prostorov&eacute;. Podtitul pak nejčastěji časov&eacute; nebo tak&eacute; prostorov&eacute; vymezen&iacute;, p&iacute;&scaron;e se pod titul a je oproti němu vyveden mal&yacute;mi p&iacute;smeny men&scaron;&iacute; velikosti.</p>
    <h3><strong>Z&aacute;kladn&iacute; kartografick&eacute; z&aacute;sady při tvorbě n&aacute;zvu</strong></h3>
    <p>Z v&yacute;&scaron;e uveden&yacute;ch skutečnost&iacute; shrneme ve stručnosti&nbsp;<strong>z&aacute;kladn&iacute; kartografick&eacute; z&aacute;sady při tvorbě n&aacute;zvu:</strong></p>
    <ul>
    <li>obsahuje věcn&eacute;, prostorov&eacute; a časov&eacute; určen&iacute; (existuj&iacute; v&yacute;jimky, viz v&yacute;&scaron;e)</li>
    <li>neobsahuje slovo &bdquo;mapa&ldquo;, stručnost</li>
    <li>bezpatkov&eacute;, dostatečně velk&eacute; p&iacute;smo</li>
    <li>ps&aacute;n kapit&aacute;lkami (smallcaps) či verzálkami</li>
    <li>pokud je př&iacute;li&scaron; dlouh&yacute;, rozděl&iacute;me ho na titul a podtitul, který je psán menší velikostí fontu a již není vyveden kapitálkami či verzálkami</li>
    </ul>

    <hr />
    <ImgCon><Gis /><div>
    <h2>Studijn&iacute; materi&aacute;ly GIS, geoinformatika a&nbsp;kartografie</h2>
    <p>T&eacute;ma <strong>Název mapy</strong> je souč&aacute;st&iacute; <Link to="/gis/">studijn&iacute;ch materi&aacute;lů GIS, geoinformatika a kartografie</Link> vytvořených zejména na podkladě předmětů vyučovaných na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat, případně na téma předch&aacute;zej&iacute;c&iacute; či n&aacute;sleduj&iacute;c&iacute;.</p>
    <BtnWrap>
    <Link to="/gis/"><button>Rozcestník GIS</button></Link>
    <Link to="/gis/mapova-kompozice/legenda/"><button className="inv">&larr; Legenda mapy</button></Link>
    <Link to="/gis/mapova-kompozice/smerovka/"><button className="inv">Směrovka na mapě &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
